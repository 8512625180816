import {
  ALLOWED_IMAGE_EXTENSIONS,
  TRUSTED_DOMAINS,
} from '@/components/AppchainIdenticon/AppchainIdenticon.constants';
import { IdentityIcon } from '@/components/IndentityIcon';
import { Center, Image } from '@mantine/core';
import * as Sentry from '@sentry/nextjs';
import NextImage from 'next/image';
import { memo, useState } from 'react';

export interface Props {
  paraId: number;
  logoUrl?: string;
  size?: number;
  bg?: string;
}

function checkIfURLIsTrusted(url: string): boolean {
  try {
    const { hostname, protocol, pathname } = new URL(url);
    const extension = pathname.split('.').pop()?.toLowerCase() ?? '';

    return (
      protocol === 'https:' &&
      TRUSTED_DOMAINS.includes(hostname) &&
      ALLOWED_IMAGE_EXTENSIONS.includes(extension)
    );
  } catch (_e) {
    return false;
  }
}

export const AppchainIdenticon = memo(function AppchainIdenticon({
  paraId,
  logoUrl,
  size = 48,
  bg = 'dark.6',
}: Props) {
  const [isError, setIsError] = useState<boolean>(false);

  function handleError(e: React.SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();
    setIsError(true);
    Sentry.captureException(e, { extra: { paraId, logoUrl } });
  }

  return (
    <>
      {logoUrl && checkIfURLIsTrusted(logoUrl) && !isError ? (
        <Image
          component={NextImage}
          src={logoUrl}
          width={size}
          height={size}
          onError={handleError}
          alt={`Appchain ${paraId} logo`}
          style={{ borderRadius: '50%' }}
        />
      ) : (
        <Center p={4} bg={bg} h={size} w={size} style={{ borderRadius: '50%' }}>
          <IdentityIcon value={paraId.toString()} size={size - 4} />
        </Center>
      )}
    </>
  );
});
