import { RegisterChainCard } from '@/components/pages/CreateAppChain/steps/RegisterChainStep/RegisterChainCard';
import { StepWrapper } from '@/components/pages/CreateAppChain/steps/StepWrapper';
import {
  useIsRegisteredInRelay,
  useIsRegisteredInTanssi,
  useRegisterInRelay,
  useRegisterInTanssi,
} from '@/hooks/polkadot/common';
import { useChainConfig } from '@/hooks/useChainConfig';
import { Group, Text } from '@mantine/core';

export function RegisterChainStep() {
  const config = useChainConfig();

  const {
    isLoading: isLoadingTanssi,
    isReadyToRegister: isReadyToRegisterInTanssi,
    onRegister: onRegisterInTanssi,
  } = useRegisterInTanssi(config);

  const {
    isLoading: isLoadingRelay,
    isReadyToRegister: isReadyToRegisterInRelay,
    onRegister: onRegisterInRelay,
  } = useRegisterInRelay(config);

  const { isRegistered: isRegisteredInTanssi, isLoading: isMountingTanssi } =
    useIsRegisteredInTanssi(config);

  const { isRegistered: isRegisteredInRelay, isLoading: isMountingRelay } =
    useIsRegisteredInRelay(config.relay);

  const isMountedRelay = !isMountingRelay && isReadyToRegisterInRelay;
  const isMountedTanssi =
    isMountedRelay && !isMountingTanssi && isReadyToRegisterInTanssi;

  return (
    <StepWrapper>
      <StepWrapper.Title>
        {!isMountingTanssi && isRegisteredInTanssi
          ? 'You Have Registered Your Appchain Successfully'
          : 'Register Your Appchain'}
      </StepWrapper.Title>
      <StepWrapper.Card>
        <Text size={'lg'} ta={'center'}>
          {
            'Register your appchain with Tanssi to become an appchain. Each transaction may take up to several minutes.'
          }
        </Text>
        <Group
          mih={270}
          mt={'xl'}
          justify={'center'}
          align={'center'}
          gap={'xl'}
        >
          <RegisterChainCard
            position={1}
            chainName={'Relay'}
            isMounted={isMountedRelay}
            isLoading={isLoadingRelay}
            isDisabled={isRegisteredInRelay}
            isRegistered={isRegisteredInRelay}
            onClick={onRegisterInRelay}
          />
          <RegisterChainCard
            position={2}
            chainName={'Tanssi'}
            isMounted={isMountedTanssi}
            isLoading={isLoadingTanssi}
            isDisabled={
              !isRegisteredInRelay ||
              !isReadyToRegisterInTanssi ||
              isLoadingRelay
            }
            isRegistered={!!isRegisteredInTanssi}
            onClick={onRegisterInTanssi}
          />
        </Group>
      </StepWrapper.Card>
    </StepWrapper>
  );
}
