import { ChainConfig } from '@/config';
import { useAppchainApi } from '@/hooks/polkadot/appchain';
import {
  useLatestAuthor,
  usePendingParaIds,
  useRegisteredParaIds,
} from '@/hooks/polkadot/common';
import { usePendingVerificationHttp } from '@/hooks/polkadot/common/queries/usePendingVerificationHttp';
import { isDefined } from '@/utils/common';
import { useEffect, useMemo, useState } from 'react';

export enum AppChainStatus {
  NotFound = 'not registered',
  PendingVerification = 'pending',
  Deploying = 'deploying',
  Online = 'live',
  NotOnline = 'not online',
}

export function useAppchainStatus(
  paraId: number | undefined,
  config: ChainConfig,
): {
  status: AppChainStatus | undefined;
  isLoading: boolean;
} {
  const [status, setStatus] = useState<AppChainStatus>();

  const appchainApi = useAppchainApi(paraId, config);
  const pendingParaIds = usePendingParaIds(config);
  const { data: isPendingVerification } = usePendingVerificationHttp(
    paraId,
    config,
    !!paraId,
  );
  const registeredParaIds = useRegisteredParaIds(config);
  const latestAuthor = useLatestAuthor(paraId, config);

  const isPending = useMemo(
    () => pendingParaIds?.some((v) => v === paraId),
    [paraId, pendingParaIds],
  );
  const isRegistered = useMemo(
    () => registeredParaIds?.some((v) => v === paraId),
    [paraId, registeredParaIds],
  );

  const online = isRegistered && appchainApi?.isConnected;
  const pending = isPendingVerification && !isRegistered && !isPending;
  const deploying = isPending && !isRegistered;
  const notOnline = isRegistered && latestAuthor?.blockNumber && !online;

  useEffect(() => {
    if (
      !isDefined(pending) ||
      !isDefined(deploying) ||
      !isDefined(notOnline) ||
      !isDefined(online)
    )
      return;

    if (pending) {
      setStatus(AppChainStatus.PendingVerification);
    }

    if (deploying) {
      setStatus(AppChainStatus.Deploying);
    }

    if (notOnline) {
      setStatus(AppChainStatus.NotOnline);
    }

    if (online) {
      setStatus(AppChainStatus.Online);
    }

    if (!pending && !deploying && !notOnline && !online) {
      setStatus(AppChainStatus.NotFound);
    }
  }, [deploying, notOnline, online, pending]);

  return { status, isLoading: !isDefined(status) };
}
