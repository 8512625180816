import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/create.hooks';
import { ChainConfig } from '@/config';
import { AppChainStatus, useAppchainStatus } from '@/hooks/polkadot/appchain';
import { isDefined } from '@/utils/common';

export function useIsRegisteredInTanssi(config: ChainConfig): {
  isRegistered: boolean;
  isLoading: boolean;
} {
  const { paraId } = useCreateAppChainInfo();
  const { status, isLoading } = useAppchainStatus(paraId, config);

  return {
    isLoading,
    isRegistered: isDefined(status) && status !== AppChainStatus.NotFound,
  };
}
