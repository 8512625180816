import { Title, TitleProps } from '@mantine/core';
import { ReactNode } from 'react';

interface Props extends TitleProps {
  children?: ReactNode;
}

export function MainTitle({ children, ...others }: Props) {
  return (
    <Title
      size={44}
      fw={800}
      c={'white'}
      {...others}
      style={{ ...others.style, fontFamily: 'Epilogue Extra Bold, sans-serif' }}
    >
      {children}
    </Title>
  );
}
