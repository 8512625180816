import { INavLink, NavLink } from '@/components/Nav/NavLink';
import { useIsAppchainViewEnabledFlag } from '@/hooks/flags/useIsAppchainViewEnabledFlag';
import { Stack } from '@mantine/core';
import {
  IconChartPie4,
  IconDeviceDesktop,
  IconEdit,
  IconFileDescription,
  IconLayoutDashboard,
  IconSchool,
  IconStack2,
} from '@tabler/icons-react';
import { BlockChainIcon } from '../icons/BlockChainIcon';

const iconProps = {
  size: 22,
  stroke: '1.5',
  color: 'var(--mantine-color-tanssiTeal-9)',
};
const NAV_LINKS = [
  {
    label: 'My Dashboard',
    path: '/',
    icon: <IconLayoutDashboard {...iconProps} />,
  },
  {
    label: 'Launch Appchain',
    path: '/create',
    icon: <IconEdit {...iconProps} />,
  },
  {
    label: 'Demo Appchain',
    path: '/demo',
    icon: <BlockChainIcon {...iconProps} />,
  },
  {
    label: 'All Appchains',
    path: '/appchains',
    icon: <IconChartPie4 {...iconProps} />,
  },
  {
    label: 'Staking',
    path: '/staking',
    icon: <IconStack2 {...iconProps} />,
  },
  {
    label: 'Tutorials',
    path: 'https://youtube.com/watch?v=Ovasc7uAXpk&list=PLEgIGlavjXIjiFacNWXn2Bmk4R1F_B8LB/',
    icon: <IconSchool {...iconProps} />,
  },
  {
    label: 'Docs',
    path: 'https://docs.tanssi.network/',
    icon: <IconFileDescription {...iconProps} />,
  },
  {
    label: 'Ecosystem',
    path: 'https://dashboard.tanssi-chains.network/',
    icon: <IconChartPie4 {...iconProps} />,
  },
  {
    label: 'Dev Support',
    path: 'https://discord.gg/tw7muAspeW',
    icon: <IconDeviceDesktop {...iconProps} />,
  },
] satisfies INavLink[];

interface Props {
  onClick?: () => void;
}

export function NavLinks({ onClick }: Props) {
  const { isEnabled: isAppchainViewEnabled } = useIsAppchainViewEnabledFlag();

  const navLinks = NAV_LINKS.filter(
    (link) =>
      link.label !== (isAppchainViewEnabled ? 'Ecosystem' : 'Appchains'),
  );

  return (
    <Stack gap={5} w={'100%'}>
      {navLinks.map((link) => (
        <NavLink key={link.label} onClick={onClick} {...link} />
      ))}
    </Stack>
  );
}
